<template>
  <div class="col-md-12">
    <v-data-table
      v-model="selected"
      item-key="idOrderStock"
      :show-select="showSelect"
      :headers="headers"
      :items="dataList"
      :search="search"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
              class="pr-5"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand pr-3"
              @click="viewRecord(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-eye pt-5"></i>
            </span>
          </template>
          <span> {{ $t("table.detail") }} </span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand text-fmedical-red"
              @click="downloadPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fa fa-file-pdf-o"></i>
            </span>
          </template>
          <span> {{ $t("table.download_PDF") }} </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.statusName`]="{ item }">
        <ChipTableComponent
          :status="item.status"
          :statusName="item.statusName"
        />
      </template>

      <template v-slot:[`item.status`]="{ item }">
          <v-chip color="green" dark class="m-1">
              Activo
          </v-chip>
      </template>
    </v-data-table>

    <ButtonTableComponent v-if="isButton" v-on:excelTable="excelTable" />

    <div class="row" v-if="isButtonDetail">
      <div
        v-if="
          pickingStatus == 2
            ? withPermissionName(permissions.PIC_PIC_END_02)
            : withPermissionName(permissions.PIC_PIC_VALI_END_02)
        "
        class="col-12 pt-10 text-right"
      >
        <button
          :class="
            pickingStatus == 2
              ? 'btn btn-outline-success'
              : 'btn btn-outline-warning'
          "
          @click="validateCheck"
        >
          <i
            :class="
              (pickingStatus == 2 ? 'ti-share-alt' : 'ti-check-box ') + ' mr-2'
            "
          ></i>
          {{
            pickingStatus == 2
              ? $t("global.finish_picking")
              : $t("global.finish_picking_validation")
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import ChipTableComponent from "@/common/table/ChipTableComponent";
import { PermissionConstants, UtilFront } from "@/core";
export default {
  props: {
    headers: {},
    isButtonDetail: false,
    isButton: false,
    showSelect: false,
    pickingStatus: 0,
  },
  data() {
    return {
      selected: [],
      search: "",
      dataList: [],
      permissions: PermissionConstants,
    };
  },
  components: {
    ButtonTableComponent,
    ChipTableComponent,
  },
  methods: {
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    /* General */
    excelTable() {
      this.$emit("excelTable");
    },
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    reloadComponentClean() {
      this.selected = [];
      this.dataList = [];
    },
    deleteRecord(item) {
      this.$emit("deleteRecord", item);
    },
    updateRecord(item) {
      this.$emit("updateRecord", item);
    },
    viewRecord(item) {
      this.$emit("viewRecord", item, 0, 4);
    },
    downloadPDF(item) {
      this.$emit("downloadPDF", item);
    },

    /* Picking */
    validateCheck() {
      let response = {};
      //Conteo de select validados y lista de envios por orden
      let sel = this.selected.length;
      let list = this.dataList.length;
      if (sel == list) {
        response.success = true;
      } else {
        response.success = false;
        response.message = "Empty_Fields_0002";
      }
      this.$emit("finishPicking", response);
    },
  },
  created() {},
};
</script>
