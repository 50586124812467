<template>
<div v-if="shipments.length > 0">
    <div class="row">
        <div class="col-12">
            <v-text-field v-model="searchT" :label="$t('table.search')" append-icon="mdi-magnify" @keyup="search($event.target.value)"></v-text-field>
        </div>
        <div v-if="shipmentList.length < 1" class="col-md-6 col-lg-12 col-xl-12 text-center">
            <b-card-title><i class="feather icon-file mr-1 fa-lg"></i></b-card-title>
            <b-card-text>
                {{$t('global.not_result_card')}}                
            </b-card-text>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3" v-for=" (item, index) in paginated" :key="index">
            <a @click="getDetailOrder( item, index, status )">
                <div :class=" 'card card-wrapper-pickings' + shipmentColor( item.type ) + 'mb-3' ">
                    <i v-if="item.type == 1" id="icon" class="ti-truck fa-2x"></i>
                    <i v-if="item.type == 2" id="icon" class="fa fa-asterisk fa-2x"></i>
                    <div class="card-header">
                        <h5 class="card-title" style="font-size: 16px">{{ item.branchOfficeDestination.name }} -
                            <small class="text-muted" style="font-size: 13px">{{ item.branchOfficeDestination.branchOfficeKey }}</small>
                        </h5>
                    </div>
                    <div class="card-body">
                        <h6><i :class=" 'w ti-truck mr-2 ' + shipmentTagColor( item.type ) "></i> <strong> {{$t('picking.shipping_id')}} </strong> {{item.idShipments}}</h6>
                        <h6><i :class=" 'w ti-pin-alt mr-2 ' + shipmentTagColor( item.type ) "></i> <strong>{{$t('picking.order_id')}}</strong> {{item.idOrder}} </h6>
                        <h6 v-if="item.type == 1">
                            <i :class=" 'w ti-truck mr-2 ' + shipmentTagColor( item.type ) "></i><strong> {{$t('picking.motive')}} </strong> {{$t('picking.starting_order_general')}}  </h6>
                        <h6 v-if="item.type == 2">
                            <i :class=" 'w fa fa-asterisk mr-2 ' + shipmentTagColor( item.type ) "></i> <strong> {{$t('picking.motive')}} </strong> {{$t('picking.starting_order_reason')}} </h6>
                    </div>
                </div>
            </a>
        </div>

        <div class="col-12">
            <nav aria-label="">
                <ul class="pagination justify-content-center">
                    <li class="page-item">
                        <button :disabled="isZero" class="btn btn-outline-success mr-2" @click="prev()">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('global.previous')}} 
                        </button>
                    </li>
                    <li class="page-item">
                        <button :disabled="isEnd" class="btn btn-outline-success" @click="next()">
                            {{$t('global.next')}}  <i class="fa fa-arrow-right ml-1"></i>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div v-else>
    <div class="col-md-6 col-lg-12 col-xl-12 text-center">
        <b-card-title><i class="feather icon-file mr-1 fa-lg"></i></b-card-title>

        <b-card-text>
            {{$t('label.no_results')}}
        </b-card-text>
    </div>
</div>
</template>

<script>
export default {
    props: {
        status: 0
    },
    data() {
        return {
            searchT: "",
            current: 1,
            pageSize: 8,
            shipments: [],
            shipmentList: []
        };
    },
    computed: {
        indexStart() {
            return (this.current - 1) * this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            return this.shipmentList.slice(this.indexStart, this.indexEnd);
        },
        isZero() {
            return (this.current - 1) == 0;
        },
        isEnd() {
            return this.indexEnd >= this.shipmentList.length;
        },
        isEmpty() {
            return this.shipmentList.length == 0;
        }
    },
    methods: {
        prev() {
            this.current--;
        },
        next() {
            this.current++;
        },
        search(word) {
            let shipmentSearchList = [];
            if (word != '') {
                this.shipments.forEach(element => {
                    let fullText = element.branchOfficeDestination.fullName + " " + element.idOrder + " " + element.idShipments;
                    fullText = fullText.toLowerCase();
                    if (fullText.includes(word.toLowerCase()))
                        shipmentSearchList.push(element);
                });
                this.current = 1;
                this.shipmentList = shipmentSearchList
            } else {
                this.current = 1;
                this.shipmentList = this.shipments;
            }

        },
        reloadComponent(list) {
            this.current = 1;
            this.searchT = "";
            this.shipmentList = list;
            this.shipments = list;
        },
        shipmentColor(type) {
            let classColor = "";
            switch (type) {
                case 1:
                    classColor = " status-one ";
                    break;
                case 2:
                    classColor = " status-two ";
                    break;
                case 3:
                    classColor = " status-two ";
                    break;
                default:
                    break;
            }
            return classColor;
        },
        shipmentTagColor(type) {
            let classColor = "";
            switch (type) {
                case 1:
                    classColor = " text-primary ";
                    break;
                case 2:
                    classColor = " text-info ";
                    break;
                case 3:
                    classColor = " text-info ";
                    break;
                default:
                    break;
            }
            return classColor;
        },
        getDetailOrder(item, index, status) {
            this.$emit("getDetailOrder", item, index, status);
        },
    }
};
</script>

<style lang="scss" scoped>
.card:hover {
    transform: scale(1.02);
}
</style>
