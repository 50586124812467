<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-xs-12 col-md-12 col-lg-12 col-xl-7">
            <!--Se agrego el "col-xs-12"-->
            <h4 class="page-title">{{ $t("picking.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("picking.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="contentbar" v-if="showAction(1)">
        <div class="row">
          <div class="col-12 no-print">
            <div class="card pb-0">
              <AlertMessageComponent class="pb-0" ref="alertMessageComponent" />
              <div
                v-show="withPermissionName(permissions.PIC_PIC_DETA_LIST_01)"
              >
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <h5 class="card-title mb-0">
                        <i class="ti-share-alt mr-2"></i>
                        {{ $t("picking.picking") }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <!-- Card de los elementos de pickig -->
                  <CardShipmentsComponent
                    ref="cardShipmentPicking"
                    :status="2"
                    v-on:getDetailOrder="getDetailOrder"
                  />
                  <!-- Card de los elementos de pickig -->
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="withPermissionName(permissions.PIC_PIC_VALI_LIST_01)"
            class="col-12 no-print"
          >
            <div class="card m-b-30">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-12">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-check-square mr-2"></i>
                      {{ $t("picking.validation_picking") }}
                    </h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <!-- Card de los elementos de validacion de picking -->
                <CardShipmentsComponent
                  ref="cardShipmentValidate"
                  :status="3"
                  v-on:getDetailOrder="getDetailOrder"
                />
                <!-- Card de los elementos de validacion de picking -->
              </div>
            </div>
          </div>
          <div
            v-show="withPermissionName(permissions.PIC_PIC_HIS_LIST_01)"
            class="col-lg-12 col-xl-12"
          >
            <div class="card m-b-30">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-12">
                    <h5 class="card-title mb-0">
                      <i class="ti-list-ol mr-"></i>
                      {{ $t("picking.history_picking") }}
                    </h5>
                  </div>
                </div>
              </div>
              <PickingTableComponent
                ref="tableHistoricalPicking"
                :headers="pickingHeaders"
                :isButton="true"
                v-on:viewRecord="getDetailOrder"
                v-on:downloadPDF="downloadPDF"
                v-on:excelTable="excelTable"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- End Contentbar -->
    </div>
    <v-dialog
      v-model="dialogPicking"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i :class="pickingIcon(currentItemPicking.pickingStatus)"></i>
              {{ $t(pickingTitle(currentItemPicking.pickingStatus)) }}
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="cleanModal()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="container">
          <div class="row">
            <AlertMessageComponent ref="alertMessageComponentModal" />
            <div class="col-md-12 pb-0 pt-7 ml-1">
              <p>
                <i class="fa fa-folder-open-o mr-2 text-fmedical-blue"></i>
                <strong>
                  <span>
                    {{ $t("title.title_pick") }}
                    {{ $t(pickingTitle(currentItemPicking.pickingStatus)) }}:
                  </span>
                </strong>
              </p>
            </div>
            <div class="col-md-12">
              <div class="card border-light">
                <div class="card-header card-header-detail">
                  <i class="fa fa-building-o mr-2 text-fmedical-blue"></i>
                  <strong>
                    <span> {{ $t("picking.destination_branch") }} </span>
                  </strong>
                  {{ currentItemPicking.branchOfficeDestination.name }}
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xs-12 col-md-4">
                      <p class="pt-3">
                        <i class="fa fa-id-card-o mr-2 text-fmedical-blue"></i>
                        <strong>
                          <span> {{ $t("picking.clues") }}</span>
                        </strong>
                        {{
                          currentItemPicking.branchOfficeDestination
                            .branchOfficeKey
                        }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-md-4">
                      <p class="pt-3">
                        <i class="ti-truck mr-2 text-fmedical-blue"></i>
                        <strong>
                          <span> {{ $t("picking.shipping_id") }}</span>
                        </strong>
                        {{ currentItemPicking.idShipments }}
                      </p>
                    </div>
                    <div class="col-xs-12 col-md-4">
                      <p class="pt-3">
                        <i class="ti-pin-alt mr-2 text-fmedical-blue"></i>
                        <strong>
                          <span> {{ $t("picking.order_id") }}</span>
                        </strong>
                        {{ currentItemPicking.idOrder }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 pb-0 pt-3 ml-1">
              <p>
                <i class="fa fa-folder-open-o mr-2 text-fmedical-blue"></i>
                <strong>
                  <span>
                    {{ $t("title.title_list") }}
                  </span>
                </strong>
              </p>
            </div>
            <PickingTableComponent
              scrollable
              class="pt-0 pb-0"
              ref="tableCheckPicking"
              :isButtonDetail="
                currentItemPicking.pickingStatus == 4 ? false : true
              "
              :headers="pickingOrderHeaders"
              :showSelect="currentItemPicking.pickingStatus == 4 ? false : true"
              :pickingStatus="currentItemPicking.pickingStatus"
              v-on:finishPicking="finishPicking"
            />
          </div>
        </div>
        <div class="row mt-8"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="js">
import {
    mapState,
    mapMutations
} from 'vuex';
import {
    UtilFront,
    PermissionConstants
} from "@/core";
import {
    ShipmentsRequest,
    OrderStockRequest,
    ExcelRequest
} from "@/core/request";
import SelectBranchOffice from '@/common/select/SelectBranchOffice.vue'
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import CardShipmentsComponent from "@/common/card/CardShipmentsComponent";
import PickingTableComponent from "@/components/picking/PickingTableComponent";

import {
    PICKING_ORDER,
    PICKING,
    SHIPMENTS_HISTORIC
} from "@/core/DataTableHeadersLocale";

export default {
    data() {
        return {
            title: "picking.picking",
            pickingHeaders: SHIPMENTS_HISTORIC(),
            pickingOrderHeaders: PICKING_ORDER(),
            /* General */
            idUser: '',
            itemOrigin: "",
            action: 1,
            loading: null,
            dialogPicking: false,
            currentItemPicking: {
                branchOfficeDestination: {}
            },

            showPicking: true,
            /* Listas de picking y validacion */
            shipmentPickingList: [],
            shipmentPickingValidateList: [],
            shipmentHistoricalPicking: [],
            permissions: PermissionConstants,

            pickingMovList: [],
        };
    },
    components: {
        SelectBranchOffice,
        AlertMessageComponent,
        PickingTableComponent,
        CardShipmentsComponent
    },
    computed: {
        ...mapState('auth', {
            branchOfficeState: 'branchOffice'
        }),
    },
    methods: {
        alert(type, message) {
          this.$refs.alertMessageComponent.reloadComponent(type, message);
        },
        alertModal(type, message) {
          this.$refs.alertMessageComponentModal.reloadComponent(type, message);
        },
        withPermissionName(namePermission) {
            return UtilFront.withPermissionName(namePermission);
        },
        // Funcion para el cambio del actionState
        ...mapMutations('auth', {
            updateAction: 'UPDATE_ACTION'
        }),
        /* General */
        showAction(action) {
            return this.action == action;
        },
        changeAction(action) {
            if (action == 1) {
                this.action = action;
            } else if (action == 2) {
                this.action = action;
            }
        },
        cleanModal() {
            this.dialogPicking = false;
            this.$refs.tableCheckPicking.reloadComponentClean();
            this.$refs.alertMessageComponentModal.reloadComponentClose();
        },
        pickingIcon(type) {
            let icon = "";
            switch (type) {
                case 2:
                    icon = " ti-share-alt mr-2";
                    break;
                case 3:
                    icon = " ti-check-box mr-2";
                    break;
                case 4:
                    icon = " ti-list-ol mr-2";
                    break;
                default:
                    break;
            }
            return icon;
        },
        pickingTitle(type) {
            let title = "";
            switch (type) {
                case 2:
                    title = 'picking.picking';
                    break;
                case 3:
                    title = 'picking.validation_picking';
                    break;
                case 4:
                    title = 'picking.history_picking';
                    break;
                default:
                    break;
            }
            return title;
        },

        /* Picking */

        //Recupero el detalle de la orden
        getDetailOrder(item, index, status) {
            //Mustro la pantalla de cargando
            this.loading = UtilFront.getLoadding();
            this.dialogPicking = true;
            this.currentItemPicking = item;
            this.currentItemPicking.pickingStatus = status;
            this.currentItemPicking.index = index;
            OrderStockRequest.orderStockDetail({
                idOrder: item.idOrder,
                status: status != 4 ? [1] : [1,3]
            }).then((response) => {
                let { status, data } = response;
                if (status == 200) {
                    this.$refs.tableCheckPicking.reloadComponent(data);
                } else if ( status == 202) {
                    this.alertModal("error", data);
                }
            }).catch((error) => {
               //console.log(error);
               this.alertModal("internal");
            }).finally(() => {
                //Elimino la pantalla de cargando
                this.loading.hide();
            });

        },
        //Finalizar la orden por status
        finishPicking(response) {
            if (response.success) {
                //Mustro la pantalla de cargando
                this.loading = UtilFront.getLoadding();
                //Cambio el status de la orden junto con el stock
                ShipmentsRequest.changeShipmentStatus({
                    idShipments: this.currentItemPicking.idShipments,
                    userBranchOffice: {
                        idUser: this.idUser,
                        idBranchOffice: this.itemOrigin
                    }
                }).then((response) => {
                    let {success,message,data } = response.data.data;
                    if (success) {
                        //Limpio toda la informacion del modal
                        this.cleanModal()
                        if (this.currentItemPicking.pickingStatus == 2) {
                            //Elimino de Picking
                           //this.shipmentPickingList.splice(this.currentItemPicking.index, 1);
                            this.shipmentPickingList = this.shipmentPickingList.filter((picking) => {
                                if (picking.idShipments !== this.currentItemPicking.idShipments) return picking;
                            })
                            //Muevo el picking a validado
                            this.shipmentPickingValidateList.push(this.currentItemPicking);
                            this.$refs.cardShipmentPicking.reloadComponent(this.shipmentPickingList);
                            this.$refs.cardShipmentValidate.reloadComponent(this.shipmentPickingValidateList);
                            //Mustro mensaje de correcto
                            this.alert("success", "Success_Picking_0001");
                        } else if (this.currentItemPicking.pickingStatus == 3) {
                            //Elimino de validacion de picking
                             //this.shipmentPickingValidateList.splice(this.currentItemPicking.index, 1);
                            this.shipmentPickingValidateList = this.shipmentPickingValidateList.filter((picking) => {
                                if (picking.idShipments !== this.currentItemPicking.idShipments) return picking;
                            })
                            this.currentItemPicking.status = 4;
                            this.currentItemPicking.statusName = 'Pendiente de Envío';
                            //Agrego a la lista de historico
                            this.shipmentHistoricalPicking.push(this.currentItemPicking);
                            this.$refs.cardShipmentValidate.reloadComponent(this.shipmentPickingValidateList);
                            this.$refs.tableHistoricalPicking.reloadComponent(this.shipmentHistoricalPicking);
                            //Mustro mensaje de correcto
                            this.alert("success", "Success_Picking_0002");
                        }
                    } else {
                       this.alertModal("error", message);
                    }
                }).catch((error) => {
                    console.log(error);
                    //this.alert("internal");
                }).finally(() => {
                    //Elimino la pantalla de cargando
                    this.loading.hide();
                });
            } else {
              this.alertModal("error", response.message);
            }
        },
        updateOrigin(data) {
            this.itemOrigin = data;
            //Mustro la pantalla de cargando
            this.loading = UtilFront.getLoadding();
            //Recupero la lista de los envios en estatus de picking y los picking validados
            ShipmentsRequest.findAllShipmentsByStatus({
                idBranchOffice: data
            }).then((response) => {
                let { success, message, data } = response.data.data;
                if (success) {
                    //Guardo las listas de picking y pendietes de validar
                    this.$refs.cardShipmentPicking.reloadComponent(data.shipmentPicking);
                    this.shipmentPickingList = data.shipmentPicking;
                    this.$refs.cardShipmentValidate.reloadComponent(data.shipmentPickingValidation);
                    this.shipmentPickingValidateList = data.shipmentPickingValidation;
                    //Envio la lista a la tabla
                    this.$refs.tableHistoricalPicking.reloadComponent(data.shipmentHistoricalPicking);
                    this.shipmentHistoricalPicking = data.shipmentHistoricalPicking;
                } else {
                    this.$refs.alertMessageComponent.reloadComponent("error", message);
                }

            }).catch(() => {
                console.error(error);
                //this.$refs.alertMessageComponent.reloadComponent("internal", "");
            }).finally(() => {
                //Elimino la pantalla de cargando
                this.loading.hide();
            });
        },
        downloadPDF(item) {
            let type = 1;
            let send = "idOrder=" + item.idOrder + "&" + "idShipment=" + item.idShipments + "&type=" + type;
            ExcelRequest.reportOrderPdf(send);
            this.$toast.success("Generando documento PDF");
        },
        /*excelTable() {
            this.$toast.success("Generando documento Xlsx");
            ExcelRequest.reportOrdersMovementHistory({
                idBranchOffice: this.itemOrigin
            });
        },*/
        excelTable() {
            this.$toast.success("Generando documento Xlsx");
            ExcelRequest.reportOrdersHistoricalPicking({
                idBranchOffice: this.itemOrigin
            });
        }

    },
    created() {
        this.idUser = UtilFront.getDataUser().idUser;
        /*  Asigan el valor del state para sucursal global */
        this.updateOrigin(this.branchOfficeState.idBranchoffice);
        this.updateAction(1);
    },
    watch: {
        branchOfficeState() {
            this.updateOrigin(this.branchOfficeState.idBranchoffice);
        },
        "$i18n.locale": function () {
            this.pickingOrderHeaders = PICKING_ORDER();
            this.pickingHeaders = SHIPMENTS_HISTORIC();
        }
    },
};
</script>

<!--Se agrego la parte de "style" para dar el formato-->

<style>
/*Código para modificar los botones */
@media (max-width: 576px) {
  .controlSize {
    text-align: center;
    float: right;
  }

  /*Código para modificar las barras de fecha o botones de busqueda*/
  .selectSize {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .controlSize {
    float: right;
  }
}
</style>
