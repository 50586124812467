<template>
  <div>
    <v-chip :color=" colorStatus( status ) " dark class="m-1 regular">
        {{ statusName }}
    </v-chip>
  </div>
</template>

<script>

export default {
    props: {
        status : 0,
        statusName : '',
    },
    methods: {
        colorStatus( statusChip ){
            let color = '';
            switch( statusChip ) {
                //Envios pendientes
                case 1: 
                color = 'default';
                break;
                //Envios en picking
                case 2:
                color = 'info';
                break;
                //Envios en validacion de picking
                case 3:
                color = 'primary';
                break;
                //Enviados
                case 4:
                color = 'orange';
                break;
                //Transito
                case 5:
                color = 'green';
                break;
                //Recibido
                case 6:
                color = 'grey';
                break;
                //Cancelado
                case 7:
                color = 'red';
                break;
                default:
                color = '';
            }
            return color;
        }
    }
};
</script>